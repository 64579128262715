import * as React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import Button from "./ui/button";
import { isNull } from "lodash";

interface IProduct {
  node: {
    frontmatter: {
      title: string;
      image: string;
      subject: string;
    };
    fields: {
      path: string;
    };
  };
}

interface Props {
  currentProduct: string;
  products: IProduct[];
}

interface State {}

const Product = styled.div`
  width: 290px;
`;

class RelatedProducts extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.printProduct = this.printProduct.bind(this);
  }
  printProduct(product: IProduct, i: number) {
    if (product.node.frontmatter.title === this.props.currentProduct) {
      return "";
    }
    return (
      <Product key={i} className="flex-none bg-white px-5 py-5 mb-10">
        <div className="flex flex-col h-full">
          <div className="md:flex-shrink-0 flex flex-col items-center align-middle justify-center mb-4">
            <img
              className="w-32"
              src={product.node.frontmatter.image}
              alt={product.node.frontmatter.title}
            />
          </div>
          <div className="mt-auto flex flex-col w-full text-center">
            <Link
              to={product.node.fields.path}
              className="block mt-1 text-lg md:text-lg leading-tight font-semibold text-gray-900 hover:underline"
            >
              {product.node.frontmatter.title}
            </Link>
            <p className="text-sm text-gray-500">
              {product.node.frontmatter.subject}
            </p>
          </div>
          <div className="flex mt-5">
            <Button
              text="Learn More"
              color="bg-blue-500"
              hover="bg-blue-700"
              link={product.node.fields.path}
            />
          </div>
        </div>
      </Product>
    );
  }
  public render() {
    const { products } = this.props;
    return (
      <div className="flex flex-no-wrap mx-5 lg:mx-5 overflow-hidden overflow-x-auto space-x-6">
        {products.map((product, i) => this.printProduct(product, i))}
      </div>
    );
  }
}

export default RelatedProducts;
