import React, { Component } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faChevronCircleRight,
  faChevronCircleLeft,
} from "@fortawesome/free-solid-svg-icons";

interface Props {
  file: string;
  buttonText: string;
}
interface State {
  closePreview: boolean;
}

const BoxContainer = styled.div`
  height: 80%;
  width: 80%;
`;

class PdfPreview extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      closePreview: true,
    };
    this.showPreview = this.showPreview.bind(this);
    this.closePreview = this.closePreview.bind(this);
  }

  showPreview(event: React.FocusEvent<EventTarget>) {
    event.preventDefault();
    this.setState({
      closePreview: false,
    });
  }
  closePreview(event: React.FocusEvent<EventTarget>) {
    event.preventDefault();
    this.setState({
      closePreview: true,
    });
  }

  render() {
    return (
      <div>
        <div
          className={
            "bg-black fixed overflow-y-auto h-screen bg-opacity-50 w-full left-0 top-0 z-50 flex justify-center align-middle" +
            (this.state.closePreview ? " hidden" : "block")
          }
        >
          <BoxContainer className="bg-white rounded-md my-auto p-3 mx-2">
            <div className="w-full flex justify-end">
              {" "}
              <button
                className="cursor-pointer text-gray-700"
                onClick={this.closePreview}
              >
                <FontAwesomeIcon
                  className="text-2xl"
                  style={{ maxWidth: "24px" }}
                  icon={faTimes}
                />
              </button>
            </div>
            <div className="h-full flex flex-col justify-center">
              <iframe
                allow="fullscreen"
                style={{ height: "80%" }}
                className="border-none w-full"
                src={this.props.file}
              />
            </div>
          </BoxContainer>
        </div>
        <button
          onClick={this.showPreview}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-2"
        >
          {this.props.buttonText}
        </button>
      </div>
    );
  }
}

export default PdfPreview;
