import * as React from "react";
import { graphql } from "gatsby"; // highlight-line

import BasicLayout from "../layouts/BasicLayout";
import Breadcrumb from "../components/ui/breadcrumb";
import ProductInfo from "../components/productInfo";
import RelatedProducts from "../components/relatedProducts";
import PageBody from "../components/ui/pageBody";
import Heading from "../components/ui/heading";
import Container from "../components/ui/container";
import Section from "../components/ui/section";
import ReactMarkdown from "react-markdown";

class Product extends React.Component {
  render() {
    const { data } = this.props;
    const product = data.product;
    const related = data.related.edges;
    return (
      <div>
        <BasicLayout pageTitle={product.frontmatter.title}>
          <div className="bg-gray-800 py-10">
            <Container padding>
              <Breadcrumb
                trail={[
                  { page: "Products", path: "/products" },
                  {
                    page:
                      product.frontmatter.subject +
                      " - " +
                      product.frontmatter.title,
                    path: this.props.uri,
                  },
                ]}
              />
              <ProductInfo
                image={product.frontmatter.image}
                category={product.frontmatter.grade}
                name={product.frontmatter.title}
                subname={product.frontmatter.subject}
                description={product.body}
                publishDate={product.frontmatter.published}
                format={product.frontmatter.format}
                isbn={product.frontmatter.isbn}
                preview={product.frontmatter.issuu_url}
                youtube_preview={
                  product.frontmatter.youtube_url
                    ? product.frontmatter.youtube_url
                    : ""
                }
              />
            </Container>
          </div>
          <div className="bg-white w-full">
            <Section>
              <Container padding>
                <Heading text="Features" />
                <PageBody>
                  <ReactMarkdown>{product.frontmatter.features}</ReactMarkdown>
                </PageBody>
              </Container>
            </Section>
          </div>
          <Section background="bg-gray-200">
            <Container padding>
              <Heading text="Related Products" />
              <RelatedProducts
                currentProduct={product.frontmatter.title}
                products={related}
              />
            </Container>
          </Section>
        </BasicLayout>
      </div>
    );
  }
}

export default Product;

// highlight-start
export const ProductQuery = graphql`
  query ProductQuery($alias: String!, $subject: String!) {
    product: mdx(fields: { path: { eq: $alias } }) {
      frontmatter {
        image
        title
        features
        format
        grade
        isbn
        issuu_url
        published(formatString: "MM YYYY")
        youtube_url
      }
      body
    }
    related: allMdx(
      filter: {
        frontmatter: { layout: { eq: "product" }, subject: { eq: $subject } }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            image
            subject
          }
          fields {
            path
          }
        }
      }
    }
  }
`;
