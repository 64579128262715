import * as React from "react";
import PdfPreview from "./pdf";
import Link from "gatsby-link";
import YoutubePreview from "./youtubePreview";
import { MDXRenderer } from "gatsby-plugin-mdx";

interface Props {
  image: string;
  category: string;
  name: string;
  subname: string;
  description: string;
  publishDate: string;
  format: string;
  isbn: string;
  preview: string;
  youtube_preview: string;
}

interface State {}

class ProductInfo extends React.Component<Props, State> {
  public render() {
    return (
      <div>
        <div className="flex flex-wrap my-10 align-middle items-center">
          <div className="flex-2 w-full lg:w-5/12 mb-5 lg:mb-0">
            <div className="md:flex-shrink-0 flex justify-center items-center">
              {this.props.image && (
                <img
                  style={{ width: "370px" }}
                  src={this.props.image}
                  alt="product"
                />
              )}
            </div>
            <div className="flex justify-center mt-5">
              <Link
                to="/contact-us"
                className="bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded mx-2"
              >
                Request Sample
              </Link>
              {this.props.preview ? (
                <PdfPreview file={this.props.preview} buttonText="Preview" />
              ) : this.props.youtube_preview ? (
                <YoutubePreview
                  link={this.props.youtube_preview}
                  buttonText="Preview"
                />
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="flex-3 w-full lg:w-7/12 lg:px-6">
            <div className="mb-2 uppercase tracking-wide text-lg text-pink-400 font-bold">
              {this.props.category}
            </div>
            <div className="font-bold text-white text-xl lg:text-4xl">
              {this.props.name}
            </div>
            <p className="font-bold text-blue-400 text-lg">
              {this.props.subname}
            </p>
            <div className="mt-8">
              <span className="font-bold text-gray-100 uppercase text-md lg:text-xl">
                Description:{" "}
              </span>
              <div
                style={{ height: "240px" }}
                className="text-gray-400 text-justify text-md xl:text-xl  lg:pr-5 overflow-y-auto"
                // dangerouslySetInnerHTML={{ __html: this.props.description! }}
              >
                <MDXRenderer>{this.props.description}</MDXRenderer>
              </div>
              <br />
              {this.props.publishDate && (
                <p className="text-gray-400 text-md lg:text-xl">
                  <span className="font-bold text-gray-100 uppercase mr-2">
                    Available From:
                  </span>{" "}
                  {this.props.publishDate}
                </p>
              )}
              {this.props.format && (
                <p className="text-gray-400 text-md lg:text-xl">
                  <span className="font-bold text-gray-100 uppercase mr-2">
                    format:
                  </span>{" "}
                  {this.props.format}
                </p>
              )}
              {this.props.isbn && (
                <p className="text-gray-400 text-md lg:text-xl">
                  <span className="font-bold text-gray-100 uppercase mr-2">
                    isbn:
                  </span>{" "}
                  {this.props.isbn}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductInfo;
