import React, { Component } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import YouTube from "@u-wave/react-youtube";

interface Props {
  link: string;
  buttonText: string;
}
interface State {
  closePreview: boolean;
}

const YTContainer = styled.div`
  iframe {
    @media only screen and (min-width: 640px) {
      width: 354px;
      height: 192px;
    }
    @media only screen and (min-width: 768px) {
      width: 414px;
      height: 238px;
    }
    @media only screen and (min-width: 1024px) {
      width: 560px;
      height: 315px;
    }
    @media only screen and (min-width: 1280px) {
      width: 691px;
      height: 384px;
    }
    @media only screen and (min-width: 1550px) {
      width: 837px;
      height: 465px;
    }
    @media only screen and (min-width: 1700px) {
      width: 918px;
      height: 510px;
    }
  }
`;

class YoutubePreview extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      closePreview: true,
    };
    this.showPreview = this.showPreview.bind(this);
    this.closePreview = this.closePreview.bind(this);
  }

  showPreview(event: React.FocusEvent<EventTarget>) {
    event.preventDefault();
    this.setState({
      closePreview: false,
    });
  }
  closePreview(event: React.FocusEvent<EventTarget>) {
    event.preventDefault();
    this.setState({
      closePreview: true,
    });
  }

  render() {
    let videoId = this.props.link.substring(this.props.link.indexOf("=") + 1);
    videoId = videoId.split(/[&]/)[0];
    return (
      <div>
        <div
          className={
            "bg-black fixed overflow-y-auto h-screen bg-opacity-50 w-full left-0 top-0 z-50 flex justify-center align-middle" +
            (this.state.closePreview ? " hidden" : "block")
          }
        >
          <YTContainer className="bg-white p-3 rounded-md my-auto mx-2">
            <div className="w-full flex justify-end">
              {" "}
              <button
                className="cursor-pointer text-gray-700"
                onClick={this.closePreview}
              >
                <FontAwesomeIcon
                  className="text-2xl"
                  style={{ maxWidth: "24px" }}
                  icon={faTimes}
                />
              </button>
            </div>
            {/* <iframe
                width="560"
                height="315"
                src={`https://www.youtube.com/embed/${videoId}?controls=0`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              /> */}
            <YouTube video={videoId} paused={this.state.closePreview} />
          </YTContainer>
        </div>
        <button
          onClick={this.showPreview}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-2"
        >
          {this.props.buttonText}
        </button>
      </div>
    );
  }
}

export default YoutubePreview;
